import './start';
import './icons/fontawesome';

// const $ = require('jquery');
// global.$ = global.jQuery = $;

import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/carousel'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/tab'
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/alert'
import 'bootstrap/js/dist/offcanvas'

const Tooltip = require('bootstrap/js/dist/tooltip')

const tooltips = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')).map(function (tooltipTriggerEl) {
	return new Tooltip(tooltipTriggerEl)
})

// import './bootstrap-select'
